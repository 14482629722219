import {RedirectRoute, Route} from "../classes/RouteTypes";

export default [
    // Index
    new RedirectRoute({path: '/', redirect: {name: 'dashboard'}, name: 'index'}),

    new Route({path: '/dashboard', name: 'dashboard',
        component: () => import('../pages/dashboard/Frame')}),

    new Route({path: '/procurements',
        component: () => import('../pages/procurements/Frame'),
        children: [
            new RedirectRoute({path: '', redirect: {name: 'procurements.list'}, name: 'procurements'}),
            new Route({path: 'list', name: 'procurements.list',
                component: () => import('../pages/procurements/children/List')}
            ),
            new Route({path: 'create', name: 'procurements.create',
                component: () => import('../pages/procurements/children/Create')}
            ),
            new Route({path: ':procurement', name: 'procurements.show',
                component: () => import('../pages/procurements/children/Show')}
            ),
            new Route({path: ':procurement/edit', name: 'procurements.edit',
                component: () => import('../pages/procurements/children/Edit')}
            ),
        ]
    }),

    new Route({path: '/administration',
        component: () => import('../pages/administration/Frame'),
        permissions: ['administration'],
        children: [
            new RedirectRoute({path: '', redirect: {name: 'administration.dashboard'}, name: 'administration'}),
            new Route({path: 'dashboard', name: 'administration.dashboard',
                component: () => import('../pages/administration/children/Dashboard')}
            ),
            new Route({path: 'resource-editor', name: 'administration.resource-editor',
                component: () => import('../pages/administration/children/ResourceEditorMenu')}
            ),

            // users
            new Route({path: 'users-list', name: 'administration.users.list',
                component: () => import('../pages/administration/children/UsersList.vue')}
            ),
            new Route({path: 'user/:user', name: 'administration.users.edit',
                component: () => import('../pages/administration/children/UsersEdit.vue')}
            )
        ]
    }),

    new Route({path: '/coordination',
        component: () => import('../pages/coordination/Frame'),
        permissions: ['coordination'],
        children: [
            new RedirectRoute({path: '', redirect: {name: 'coordination.list'}, name: 'coordination'}),
            new Route({path: 'list', name: 'coordination.list',
                component: () => import('../pages/coordination/children/List')}
            ),
            new Route({path: ':procurement', name: 'coordination.show',
                component: () => import('../pages/coordination/children/Show')}
            ),
            new Route({path: ':procurement/edit', name: 'coordination.edit',
                component: () => import('../pages/coordination/children/Edit')}
            )
        ]
    }),

    new Route({path: '/countersignation',
        component: () => import('../pages/countersignation/Frame'),
        permissions: ['countersignature'],
        children: [
            new RedirectRoute({path: '', redirect: {name: 'countersignation.list'}, name: 'countersignation'}),
            new Route({path: 'list', name: 'countersignation.list',
                component: () => import('../pages/countersignation/children/List')}
            ),
            new Route({path: ':procurement', name: 'countersignation.show',
                component: () => import('../pages/countersignation/children/Show')}
            ),
            new Route({path: ':procurement/edit', name: 'countersignation.edit',
                component: () => import('../pages/countersignation/children/Edit')}
            )
        ]
    }),

    new Route({path: '/liabilityprovider',
        component: () => import('../pages/liabilityprovider/Frame'),
        permissions: ['liability'],
        children: [
            new RedirectRoute({path: '', redirect: {name: 'liabilityprovider.list'}, name: 'liabilityprovider'}),
            new Route({path: 'list', name: 'liabilityprovider.list',
                component: () => import('../pages/liabilityprovider/children/List')}
            ),
            new Route({path: ':procurement', name: 'liabilityprovider.show',
                component: () => import('../pages/liabilityprovider/children/Show')}
            )
        ]
    }),

    // Logout
    new Route({path: '/logout', label: 'Kijelentkezés', name: 'logout',
        component: () => import('../pages/logout/Frame')})
];
