<template>
    <div class="frame-main">
        <header class="frame-main__header">
            <div class="frame-main__header__logo">
                <SvgImage type="sote-logo" class="frame-main__header__logo__svg"/>
                <div type="sote-logo" class="frame-main__header__logo__title">
                    <p class="frame-main__header__logo__title__university">Semmelweis Egyetem</p>
                    <p class="frame-main__header__logo__title__project">PROVISUS</p>
                </div>
            </div>
            <div class="frame-main__header__content">
                <div>{{$route.meta.label}}</div>
            </div>
            <div class="frame-main__header__buttons">
                <LogoutButton v-if="$user.isLoggedIn()"/>
            </div>
        </header>
        <div v-if="$user.isLoggedIn()" id="secondary-header">
            <div id="secondary-header__previous-button" ref="goToPreviousPageSlot">
                <SvgButton v-if="displayPreviousButton" ref="defaultPreviousButton" type="arrow-left" @click="climbRouteNodeTree"/>
            </div>
            <div id="secondary-header__title"></div>
            <div id="secondary-header__buttons"></div>
        </div>
        <div class="frame-main__content">
            <router-view class="frame-main__content__view"></router-view>
        </div>
    </div>
</template>

<script>

import LogoutButton from "../components/buttons/LogoutButton.vue";
import SvgButton from "../components/buttons/SvgButton.vue";
import SvgImage from "../components/SvgImage.vue";

export default {
    components: {LogoutButton, SvgButton, SvgImage},

    provide() {return {
        goToDashboardEvent: this.goToDashboardEvent,
        getDashboardPageLinks: () => {return _.cloneDeep(this.dashboardPageLinks);}
    };},

    data() {return {
        displayPreviousButton: false,
        mutationObserverForPreviousButton: null,
        dashboardPageLinks: null
    };},

    beforeMount() {
        let pages = [
            {label: 'Rendszer adminisztráció', name: 'procurements.list', permission: 'administration'},
            {label: 'Igénylési tevékenységek', name: 'procurements.list', permission: 'request'},
            {label: 'Beszerzési koordináció', name: 'coordination.list', permission: 'procurement'},
            {label: 'Ellenjegyzési tevékenységek', name: 'procurements.list', permission: 'countersignature'},
            {label: 'Kötelezettségvállalási tevékenységek', name: 'procurements.list', permission: 'liability'},
        ];

        this.dashboardPageLinks = _.pickBy(pages, ((page) => {
            return this.$user.hasPermission(page.permission);
        }));
    },

    mounted() {
        /**
         * Observing the reference for teleported content and display the default button only when nothing else is
         * injected there by other components.
         *
         * @type {MutationObserver}
         */
        this.mutationObserverForPreviousButton = new MutationObserver((mutationList, observer) => {
            this.displayPreviousButton =
                // Something has been teleported if length is greater than 1.
                (this.$refs.goToPreviousPageSlot.children.length < 2) &&
                // Display the default item if the first children is itself after the DOM mutation
                // or there is nothing else inserted to show
                ((this.$refs.goToPreviousPageSlot.children[0] === this.$refs.defaultPreviousButton?.$el) || !this.$refs.goToPreviousPageSlot.children.length)

                // Minor bugfix until I can find a better solution
                && this.$router.currentRoute.value.name !== 'dashboard';
        });
        if(this.$refs.goToPreviousPageSlot) {
            this.mutationObserverForPreviousButton.observe(this.$refs.goToPreviousPageSlot, { childList: true });
        }
    },

    beforeUnmount() {
        this.mutationObserverForPreviousButton.disconnect();
    },

    watch: {
        $route(newValue, oldValue) {
            this.displayPreviousButton = newValue.matched.length > 1;
        }
    },

    methods: {
        climbRouteNodeTree() {
            let index = this.$route.matched.length - 2;

            if(index < 0) {
                return;
            }

            this.$router.push({
                path: this.$route.matched[index].path
            });
        },

        goToDashboardEvent() {
            this.$router.push({name: 'dashboard'});
        }
    }
}
</script>
