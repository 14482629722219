export default class {
    constructor() {
        this.Model = null;
        this.exposed = null;

        if(window.frontendConfiguration.user) {
            this.Model = _.cloneDeep(window.frontendConfiguration.user);
        }
    }

    install(app, options) {
        const exposed = Object.assign(this.Model ?? {}, {
            isLoggedIn: this.isLoggedIn.bind(this),
            hasRole: this.hasRole.bind(this),
            hasPermission: this.hasPermission.bind(this)
        });

        this.exposed = exposed;

        /**
         * Example:
         * this.$root.$user
         */
        app.config.globalProperties.$user = exposed;

        /**
         * Example:
         * import { inject } from 'vue';
         *
         * const UserProvider = inject('UserProvider')
         */
        app.provide('UserProvider', exposed);
    }

    /**
     * @param {string} role
     * @param {mixed} options
     * @returns {boolean}
     */
    hasRole(role, options) {
        if(!this.isLoggedIn()) {
            return false;
        }

        return this.resolve(role, this.Model.data.roles, options);
    }

    /**
     * @param {string} permission
     * @param {mixed} options
     * @returns {boolean}
     */
    hasPermission(permission, options) {
        if(!this.isLoggedIn()) {
            return false;
        }

        return this.resolve(permission, this.Model.data.permissions, options);
    }

    resolve(source, category, options) {
        if(!category) {
            return false;
        }

        if(typeof source === 'string') {
            return Object.values(category).includes(source);
        }

        let resolved = false;

        // Looking for callbacks which are obfuscated for the _.intersection as
        // they are not strings. Such things can be registered in the routes.
        Object.keys(source).forEach((key) => {
            let value = source[key];
            if(typeof value === 'function') {
                // Resolving the callback
                if(value(this.exposed, options)) {
                    resolved = true;
                }
            }
        });
        // Callback allows
        if(resolved) {
            return true;
        }

        // Looking for matches in the user's roles or permissions
        return _.intersection(source, Object.values(category)).length > 0;
    }

    /**
     * @returns {boolean}
     */
    isLoggedIn() {
        return this.Model !== null;
    }
};
