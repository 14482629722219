<template>
    <component class="svg-image" :is="type"/>
</template>

<script>
import SoteLogo from "../../media/images/svg/sote-logo.svg?component";
export default {
    components: {
        'sote-logo': SoteLogo
    },

    props: {
        /**
         * Due to the behaviour or the HTML standards kebab-cased types can be accepted only.
         *
         * @see: https://vuejs.org/guide/essentials/component-basics.html#dom-template-parsing-caveats
         */
        type: {
            required: true,
            type: String,
            validator: (value) => {
                return [
                    'sote-logo',
                ].includes(value);
            }
        }
    }
}
</script>
