import {RedirectRoute, Route} from "../classes/RouteTypes";

export default [
    // Errors
    new Route({path: '/404', name: 'http.status.404', component: () => import('../pages/errors/E_404')}),
    new Route({path: '/403', name: 'http.status.403', component: () => import('../pages/errors/E_403')}),
    new Route({path: '/401', name: 'http.status.401', component: () => import('../pages/errors/E_401')}),

    new Route({path: '/login', label: 'Bejelentkezés', name: 'login',
        component: () => import('../pages/login/Frame')}),

    // Otherwise
    new Route({path: '/:pathMatch(.*)*', component: () => import('../pages/errors/E_404')})
];
