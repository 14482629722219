<template>
    <router-link class="svg-button" v-if="route" :to="route">
        <component class="svg-button__svg" :is="type"/>
    </router-link>
    <a v-else-if="href" class="svg-button" :href="href">
        <component class="svg-button__svg" :is="type"/>
    </a>
    <div v-else class="svg-button">
        <component class="svg-button__svg" :is="type"/>
    </div>
</template>

<script>
import ArrowLeftSvg from "../../../media/images/svg/arrow-left.svg?component";
import BoxArrowInLeft from "../../../media/images/svg/box-arrow-in-left.svg?component";
import TrashFilled from "../../../media/images/svg/trash-filled.svg?component";
import PencilFilled from "../../../media/images/svg/pencil-filled.svg?component";
import EyeFilled from "../../../media/images/svg/eye-filled.svg?component";
import Download from "../../../media/images/svg/download.svg?component";

export default {
    components: {
        'arrow-left': ArrowLeftSvg,
        'box-arrow-in-left': BoxArrowInLeft,
        'trash-filled': TrashFilled,
        'pencil-filled': PencilFilled,
        'eye-filled': EyeFilled,
        'download': Download
    },

    props: {
        /**
         * Due to the behaviour or the HTML standards kebab-cased types can be accepted only.
         *
         * @see: https://vuejs.org/guide/essentials/component-basics.html#dom-template-parsing-caveats
         */
        type: {
            required: true,
            type: String,
            validator: (value) => {
                return [
                    'arrow-left',
                    'box-arrow-in-left',
                    'trash-filled',
                    'pencil-filled',
                    'eye-filled',
                    'download'
                ].includes(value);
            }
        },

        href: {
            required: false,
            type: String,
            default: null
        },

        route: {
            required: false,
            type: Object,
            default: null
        }
    }
}
</script>
