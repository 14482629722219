export const dateFormat = 'Y. F d.';
export const dateFormatVueDatePicker = 'yyyy. MMMM dd.';
export const datetimeFormat = 'Y. F d. H:i:s';

export const datetimeFormatVueDatePicker = 'yyyy. MMMM dd. hh:mm:ss';
export const UTCDatetimeFormat = 'Y-m-dTH:i:s.000000Z';

// For XHTTPRequests
export const requestDateFormat = 'Y-m-d';
export const requestDatetimeFormat = 'Y-m-dTH:i:s.000000Z';
